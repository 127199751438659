import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import './css/lineLoss-styles.css';
export const _frontmatter = {
  "key": "reusable",
  "langKey": "en",
  "title": "12V-LineLoss-1"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`For setting up LED or even a whole house 12V system, knowing how much is lost is critical`}</h1>
    <p>{`Here is the voltage drop for each 10 feet (about 3 metres, 3.048) of wire.  `}<br />{`This page is intended for Peter's personal use only.`}<br />{` Example, if the consumer of power is 100' away from the converter providing the power, and you are drawing 50 watts (4.17 amps), and you want at maximum a 1 volt drop in line voltage you need a 10 gauge wire.`}</p>
    <table>
  <tbody>
    <tr>
      <table border="1" cellSpacing="1" cellPadding="3">
        <tbody>
          <tr>
              <th rowSpan="4">LED and controller POWER  
                at source  
                Watts
              </th>
              <th>&nbsp;</th>
              <th colSpan="5">Wire gauge (AWG), Class K copper</th>
          </tr>
          <tr>
            <th>&nbsp;</th>
            <th>18</th>
            <th>16</th>
            <th>12</th>
            <th>10</th>
            <th>8</th>
          </tr>
          <tr>
            <th rowSpan="2">Amps</th>
            <th colSpan="5">Resistance (Ohms per 10' of cable, both conductors) @ 25 °C</th>
          </tr> 
          <tr>
            <th className="style1">0.1358</th>
            <th className="style1">0.0852</th>
            <th className="style1">0.0336</th>
            <th className="style1">0.0212</th>
            <th className="style1">0.0136</th>
          </tr>
          <tr>
            <td>20</td>
            <td>1.67</td>
            <td>0.226</td>
            <td>0.142</td>
            <td>0.056</td>
            <td>0.035</td>
            <td>0.023</td>
          </tr>
          <tr>
            <td>25</td>
            <td>2.08</td>
            <td>0.283</td>
            <td>0.178</td>
            <td>0.070</td>
            <td>0.044</td>
            <td>0.028</td>
          </tr>
          <tr>
            <td>30</td>
            <td>2.50</td>
            <td>0.340</td>
            <td>0.213</td>
            <td>0.084</td>
            <td>0.053</td>
            <td>0.034</td>
          </tr>
          <tr>
            <td>35</td>
            <td>2.92</td>
            <td>0.396</td>
            <td>0.249</td>
            <td>0.098</td>
            <td>0.062</td>
            <td>0.040</td>
          </tr>
          <tr>
            <td>40</td>
            <td>3.33</td>
            <td>0.453</td>
            <td>0.284</td>
            <td>0.112</td>
            <td>0.071</td>
            <td>0.045</td>
          </tr>
          <tr>
            <td>45</td>
            <td>3.75</td>
            <td>0.509</td>
            <td>0.320</td>
            <td>0.126</td>
            <td>0.080</td>
            <td>0.051</td>
          </tr>
          <tr>
            <td>50</td>
            <td>4.17</td>
            <td>0.566</td>
            <td>0.355</td>
            <td>0.140</td>
            <td>0.088</td>
            <td>0.057</td>
          </tr>
          <tr>
            <td>55</td>
            <td>4.58</td>
            <td>0.622</td>
            <td>0.391</td>
            <td>0.154</td>
            <td>0.097</td>
            <td>0.062</td>
          </tr>
          <tr>
            <td>60</td>
            <td>5.00</td>
            <td>0.679</td>
            <td>0.426</td>
            <td>0.168</td>
            <td>0.106</td>
            <td>0.068</td>
          </tr>
          <tr>
            <td>65</td>
            <td>5.42</td>
            <td>0.736</td>
            <td>0.462</td>
            <td>0.182</td>
            <td>0.115</td>
            <td>0.074</td>
          </tr>
          <tr>
            <td>70</td>
            <td>5.83</td>
            <td>0.792</td>
            <td>0.497</td>
            <td>0.196</td>
            <td>0.124</td>
            <td>0.079</td>
          </tr>
          <tr>
            <td>75</td>
            <td>6.25</td>
            <td>0.849</td>
            <td>0.533</td>
            <td>0.210</td>
            <td>0.133</td>
            <td>0.085</td>
          </tr>
          <tr>
            <td>80</td>
            <td>6.67</td>
            <td>0.905</td>
            <td>0.568</td>
            <td>0.224</td>
            <td>0.141</td>
            <td>0.091</td>
          </tr>
          <tr>
            <td>85</td>
            <td>7.08</td>
            <td>0.962</td>
            <td>0.604</td>
            <td>0.238</td>
            <td>0.150</td>
            <td>0.096</td>
          </tr>
          <tr>
            <td>90</td>
            <td>7.50</td>
            <td>1.019</td>
            <td>0.639</td>
            <td>0.252</td>
            <td>0.159</td>
            <td>0.102</td>
          </tr>
          <tr>
            <td>95</td>
            <td>7.92</td>
            <td>1.075</td>
            <td>0.675</td>
            <td>0.266</td>
            <td>0.168</td>
            <td>0.108</td>
          </tr>
          <tr>
            <td style={{
                  "borderBottomWidth": "2px"
                }}>100</td>
            <td style={{
                  "borderBottomWidth": "2px"
                }}>8.33</td>
            <td style={{
                  "borderBottomWidth": "2px"
                }}>-</td>
            <td style={{
                  "borderBottomWidth": "2px"
                }}>0.710</td>
            <td style={{
                  "borderBottomWidth": "2px"
                }}>0.280</td>
            <td style={{
                  "borderBottomWidth": "2px"
                }}>0.177</td>
            <td style={{
                  "borderBottomWidth": "2px"
                }}>0.113</td>
          </tr>
          <tr>
            <td>105</td>
            <td>8.75</td>
            <td>-</td>
            <td>0.746</td>
            <td>0.294</td>
            <td>0.186</td>
            <td>0.119</td>
          </tr>
          <tr>
            <td>110</td>
            <td>9.17</td>
            <td>-</td>
            <td>0.781</td>
            <td>0.308</td>
            <td>0.194</td>
            <td>0.124</td>
          </tr>
          <tr>
            <td>115</td>
            <td>9.58</td>
            <td>-</td>
            <td>0.817</td>
            <td>0.322</td>
            <td>0.203</td>
            <td>0.130</td>
          </tr>
          <tr>
            <td>120</td>
            <td>10.00</td>
            <td>-</td>
            <td>0.852</td>
            <td>0.336</td>
            <td>0.212</td>
            <td>0.136</td>
          </tr>
          <tr>
            <td>125</td>
            <td>10.42</td>
            <td>-</td>
            <td>-</td>
            <td>0.350</td>
            <td>0.221</td>
            <td>0.141</td>
          </tr>
          <tr>
            <td>130</td>
            <td>10.83</td>
            <td>-</td>
            <td>-</td>
            <td>0.364</td>
            <td>0.230</td>
            <td>0.147</td>
          </tr>
          <tr>
            <td>135</td>
            <td>11.25</td>
            <td>-</td>
            <td>-</td>
            <td>0.378</td>
            <td>0.239</td>
            <td>0.153</td>
          </tr>
          <tr>
            <td>140</td>
            <td>11.67</td>
            <td>-</td>
            <td>-</td>
            <td>0.392</td>
            <td>0.247</td>
            <td>0.158</td>
          </tr>
          <tr>
            <td>145</td>
            <td>12.08</td>
            <td>-</td>
            <td>-</td>
            <td>0.406</td>
            <td>0.256</td>
            <td>0.164</td>
          </tr>
          <tr>
            <td>150</td>
            <td>12.50</td>
            <td>-</td>
            <td>-</td>
            <td>0.420</td>
            <td>0.265</td>
            <td>0.170</td>
          </tr>
          <tr>
            <td>155</td>
            <td>12.92</td>
            <td>-</td>
            <td>-</td>
            <td>0.434</td>
            <td>0.274</td>
            <td>0.175</td>
          </tr>
          <tr>
            <td>160</td>
            <td>13.33</td>
            <td>-</td>
            <td>-</td>
            <td>0.448</td>
            <td>0.283</td>
            <td>0.181</td>
          </tr>
          <tr>
            <td>165</td>
            <td>13.75</td>
            <td>-</td>
            <td>-</td>
            <td>0.462</td>
            <td>0.292</td>
            <td>0.187</td>
          </tr>
          <tr>
            <td>170</td>
            <td>14.17</td>
            <td>-</td>
            <td>-</td>
            <td>0.476</td>
            <td>0.300</td>
            <td>0.192</td>
          </tr>
          <tr>
            <td>175</td>
            <td>14.58</td>
            <td>-</td>
            <td>-</td>
            <td>0.490</td>
            <td>0.309</td>
            <td>0.198</td>
          </tr>
          <tr>
            <td>180</td>
            <td>15.00</td>
            <td>-</td>
            <td>-</td>
            <td>0.504</td>
            <td>0.318</td>
            <td>0.204</td>
          </tr>
          <tr>
            <td>185</td>
            <td>15.42</td>
            <td>-</td>
            <td>-</td>
            <td>0.518</td>
            <td>0.327</td>
            <td>0.209</td>
          </tr>
          <tr>
            <td>190</td>
            <td>15.83</td>
            <td>-</td>
            <td>-</td>
            <td>0.532</td>
            <td>0.336</td>
            <td>0.215</td>
          </tr>
          <tr>
            <td>195</td>
            <td>16.25</td>
            <td>-</td>
            <td>-</td>
            <td>0.546</td>
            <td>0.345</td>
            <td>0.221</td>
          </tr>
          <tr>
            <td style={{
                  "borderBottomWidth": "2px"
                }}>200</td>
            <td style={{
                  "borderBottomWidth": "2px"
                }}>16.67</td>
            <td style={{
                  "borderBottomWidth": "2px"
                }}>-</td>
            <td style={{
                  "borderBottomWidth": "2px"
                }}>-</td>
            <td style={{
                  "borderBottomWidth": "2px"
                }}>0.560</td>
            <td style={{
                  "borderBottomWidth": "2px"
                }}>0.353</td>
            <td style={{
                  "borderBottomWidth": "2px"
                }}>0.226</td>
          </tr>
          <tr>
            <td>205</td>
            <td>17.08</td>
            <td>-</td>
            <td>-</td>
            <td>0.574</td>
            <td>0.362</td>
            <td>0.232</td>
          </tr>
          <tr>
            <td>210</td>
            <td>17.50</td>
            <td>-</td>
            <td>-</td>
            <td>0.588</td>
            <td>0.371</td>
            <td>0.238</td>
          </tr>
          <tr>
            <td>215</td>
            <td>17.92</td>
            <td>-</td>
            <td>-</td>
            <td>0.602</td>
            <td>0.380</td>
            <td>0.243</td>
          </tr>
          <tr>
            <td>220</td>
            <td>18.33</td>
            <td>-</td>
            <td>-</td>
            <td>0.616</td>
            <td>0.389</td>
            <td>0.249</td>
          </tr>
          <tr>
            <td>225</td>
            <td>18.75</td>
            <td>-</td>
            <td>-</td>
            <td>0.630</td>
            <td>0.398</td>
            <td>0.255</td>
          </tr>
          <tr>
            <td>230</td>
            <td>19.17</td>
            <td>-</td>
            <td>-</td>
            <td>0.644</td>
            <td>0.406</td>
            <td>0.260</td>
          </tr>
          <tr>
            <td>235</td>
            <td>19.58</td>
            <td>-</td>
            <td>-</td>
            <td>0.658</td>
            <td>0.415</td>
            <td>0.266</td>
          </tr>
          <tr>
            <td>240</td>
            <td>20.00</td>
            <td>-</td>
            <td>-</td>
            <td>0.672</td>
            <td>0.424</td>
            <td>0.272</td>
          </tr>
        </tbody>
      </table>
    </tr>
  </tbody>
    </table>
    <table>
  <tbody>
    <tr>
      <td style={{
            "verticalAlign": "top",
            "textDecoration": "underline",
            "textTransform": "uppercase"
          }}>note:</td>
      <td>
        <ol>
          <li>Why blank spots? The chart shows voltage drops for maximum of 80% of 
          the cable's max amperage.</li>
          <li>AIt provides voltage drops for a maximum wattage of 240 
          watts which is 80% of 300 watt transformer circuit rating.</li>
        </ol>
      </td>
		</tr>
	</tbody>
  </table>    

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      